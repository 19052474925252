import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Skills from "./components/Skills/Skills";
import Works from "./components/Works/Works";
import Nav from "./components/Nav/Nav";
import Case from "./components/case/Case";

export default function Router() {
  return (
    <Switch>
      <Route path="/skills">
        <Skills />
      </Route>
      <Route path="/work/:caseid">
        <Case />
      </Route>
      <Route path="/work">
        <Works />
      </Route>
      <Route path="/nav">
        <Nav />
      </Route>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  );
}
