import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import "./Works.css";
import snapdesign from "../../assets/interiors.png";
import { AiOutlineMenu } from "react-icons/ai";
import Example from "../example/example";
import debounce from "lodash/debounce";

const Skills = () => {
  const [workInfo] = useState({
    katy: {
      headline: "Disrupting the fitness industry",
      title: "Fit By Katy Hearn",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fiosview-1.png?alt=media&token=4dfa0efb-1129-4fdc-9ea8-9c6999c81f8a",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fs2.png?alt=media&token=1ea2a373-c11d-4526-b80f-861c790e8f8e",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fs1.png?alt=media&token=b2656e82-47ae-4648-9d76-6db389cb53a5",
      ],
      technology: [
        "React",
        "React Native",
        "JavaScript",
        "CSS3",
        "HTML5",
        "Redux",
        "Firebase",
        "Node",
        "Express",
        "NoSQL",
        "Axios",
        "Restful API",
        "React-Router",
        "Database",
        "Sass",
        "Microservices",
      ],
      products: [
        "Android App",
        "iOS App",
        "CMS",
        "CRM",
        "Internal Tools",
        "Email Campaign",
      ],
      description: "Popular Fitness Platform",
      role: "Full Stack React & React-Native Developer",
      bg: "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/ktbg.jpg?alt=media&token=6db1d33b-5176-41aa-a89f-94aefc5b2d92",
    },
    curbio: {
      headline: "Digital Age Renovations",
      title: "Curbio",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/appexample.png?alt=media&token=7645410a-3a45-42fe-a0ba-197186cbef84",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fs2.png?alt=media&token=1ea2a373-c11d-4526-b80f-861c790e8f8e",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fs1.png?alt=media&token=b2656e82-47ae-4648-9d76-6db389cb53a5",
      ],
      technology: [
        "React",
        "React Native",
        "JavaScript",
        "CSS3",
        "HTML5",
        "Redux",
        "Firebase",
        "Node",
        "Express",
        "NoSQL",
        "Axios",
        "Restful API",
        "React-Router",
        "Database",
        "Sass",
        "Microservices",
      ],
      products: [
        "Android App",
        "iOS App",
        "CMS",
        "CRM",
        "Internal Tools",
        "Email Campaign",
      ],
      description: "Popular Fitness Platform",
      role: "Full Stack React & React-Native Developer",
      bg: "https://pantheonphotos.files.wordpress.com/2011/01/painted-ladies-houses-1-san-francisco-photo-by-john-ecker.jpg",
    },
    ecp: {
      headline: "Healthcare tech at scale",
      title: "Extended Care Pro",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/ECP_logo_white-1.svg?alt=media&token=5147f6ea-d2d1-4c07-a3f9-6b69bc5d59bd",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/ecp2.png?alt=media&token=2980fd05-3195-46f8-9100-d730be0163ec",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/ecp1.png?alt=media&token=a252e60a-1a91-412b-8ad2-0163ed3aaaed",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/ecp3.png?alt=media&token=43d41a44-d3b8-42e2-bd8c-25c2508c74fb",
      ],
      technology: [
        "React Native",
        "React",
        "JavaScript",
        "HTML5",
        "CSS3",
        "Coldfusion",
        "APIs",
        "Azure",
        "Docker",
        "Expo",
        "Socket.IO",
        "Microservices",
        "Legacy",
        "Greenfield",
      ],
      products: [
        "Web Applications",
        "Android Application",
        "iOS Application",
        "Funnel Pages",
        "Virtual Conference Pages",
      ],
      description: "Assisted Living Software Suite",
      role: "Full Stack React and React-Native Developer",
      bg: "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/elderlycare.jpg?alt=media&token=916b6da5-8d40-4140-8dff-12c9d9f47651",
    },
    lutris: {
      headline: "A new way to game",
      title: "Lutris",
      images: [
        "https://lutris.net/static/images/logo.a2f1036fd4ea.png",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Flutris1.png?alt=media&token=8dd54de2-4cf0-40d6-8536-be62a93596b6",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Flutris2.png?alt=media&token=05b1103f-cb95-4ae3-ac54-71163814a073",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Flutris3.png?alt=media&token=7c85873b-7131-4c11-9f21-ea03a82ec02a",
      ],
      technology: ["Sketch", "JavaScript", "HTML5", "CSS3"],
      products: ["Web Application", "Dashboard"],
      description: "Redesign of popular gaming client for Linux",
      role: "Web Design",
      bg: "https://overwatch2-static.playoverwatch.com/5247d533591a956bf54ba216fba501ecb081356d/static/images/share.jpg",
    },
    snap: {
      headline: "Design within reach",
      title: "snap design",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Flogo.png?alt=media&token=3577a74f-c97c-40ac-afe9-5b30ba7adc16",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/1.jpg?alt=media&token=d48173a7-e6a9-4610-8825-b2d8c3087503",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/2.jpg?alt=media&token=4045aff5-344f-47eb-a4d3-4e9b4d3eb976",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/3.jpg?alt=media&token=0d5b7429-a246-4ade-bac7-c110ae2462e5",
      ],
      technology: [
        "JavaScript",
        "CSS3",
        "HTML5",
        "React",
        "Redux",
        "Node",
        "Express",
        "PostgreSQL",
        "Material-UI",
        "Firebase",
        "Axios",
        "Restful API",
        "React-Router",
      ],
      products: ["Web Application", "Restful API", "Database"],
      description: "Interior design platform",
      role: "Full Stack React Developer",
      bg: snapdesign,
    },
    trivia: {
      headline: "Interactive Entertainment",
      title: "10Q Trivia",
      images: [
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2F10qlogo.png?alt=media&token=ca66c79c-094d-4e00-9c7e-7ca3158870be",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2F10q1.jpg?alt=media&token=93b8e21c-d9c4-4beb-8df6-53be54bd99a1",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2F10q2.jpg?alt=media&token=2dbc46f6-8396-4acc-9038-f81c124ac648",
        "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2F10q3.jpg?alt=media&token=4bc32bfa-5aa5-40f5-8df1-784feb3ae039",
      ],
      technology: [
        "JavaScript",
        "CSS3",
        "HTML5",
        "React",
        "Redux",
        "Firebase",
        "Socket.Io",
        "Node",
        "Express",
        "PostgreSQL",
        "Material-UI",
        "Axios",
        "Restful API",
        "React-Router",
      ],
      products: ["Web Application", "iOS Application", "Database", "CMS"],
      description: "Real-time trivia game with chat functionality",
      role: "Full Stack React + React-Native Developer",
      bg: "https://images.pexels.com/photos/7562026/pexels-photo-7562026.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },

  });
  const [position, setPosition] = useState(0);

  const menuRef = useRef();

  // eslint-disable-next-line
  const debouncedSave = useCallback(
    debounce((nextValue) => setPosition(nextValue), 100),
    []
  );

  const handleScrollChange = (yScroll) => {
    debouncedSave(yScroll);
  };

  return (
    <div
      className="selected-works"
      onScroll={() => {
        const yScroll = menuRef.current.scrollLeft;
        handleScrollChange(yScroll);
      }}
      ref={menuRef}
    >
      <div
        style={{
          display: "flex",
          alignItems: "space-between",
          width: "100vw",
          position: "absolute",
          top: 0,
          left: position,
          backgroundColor: "transparent",
        }}
      >
        <div className="name-container">
          <div className="menuname slideup">
            <Link to={"/"}>Josh Godinez</Link>
          </div>
        </div>
        <div
          className=""
          style={{
            display: "flex",
            flex: 2,
            justifyContent: "flex-end",
            padding: "1.5em",
            textTransform: "capitalize",
          }}
        >
          <Link to={"/nav"}>
            <div
              className="element icon slideup grow"
              style={{ color: "white" }}
            >
              <AiOutlineMenu />
            </div>
          </Link>
        </div>
      </div>

      <div className="work">
        {Object.entries(workInfo).map((project, index) => {
          return (
            <Example
              title={project[1].title}
              key={`${project.title}-${index}`}
              bg={project[1].bg}
              headline={project[1].headline}
            />
          );
        })}
      </div>
    </div>
  );
};
export default Skills;
