// dependencies
import React from "react";
// components
import Name from "../Name";
import Menu from "../Menu";
// styles
import "./Home.css";

const Home = ({ props }) => {
  // calculateDistance = (elem, mouseX, mouseY) => {
  //   return Math.floor(
  //     Math.sqrt(
  //       Math.pow(mouseX - (elem.offsetLeft + elem.clientWidth / 2), 2) +
  //         Math.pow(mouseY - (elem.offsetTop + elem.clientHeight / 2), 2)
  //     )
  //   );
  // };

  // animate = (e) => {
  //   const uniforms = { uOffset: 0.024, uRotation: 0.4 };

  //   let mouse = { x: e.clientX, y: e.clientY };
  //   let distance = this.calculateDistance(this.split, mouse.x, mouse.y);
  //   const center = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
  //   const deltaX = Math.floor(center.x - mouse.x) * -0.45;
  //   const deltaY = Math.floor(center.y - mouse.y) * -0.45;

  //   distance = distance > 360 ? 360 : distance;

  //   const newRotation = (uniforms.uRotation = deltaX + deltaY * 0.1);
  //   const newOffset = (uniforms.uOffset = distance * 0.00004 + 0.02);

  //   this.setState({
  //     x: newRotation,
  //     y: newOffset,
  //   });
  // };

  // componentDidMount() {
  //   // const rando45 = Math.random() * 45;
  //   // this.setState({
  //   //   rando45,
  //   //   showMessage: true,
  //   // });
  // }
  return (
    <div className="Home">
      <div
        style={{
          display: "flex",
          alignItems: "space-between",
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: 100,
          backgroundColor: "transparent",
          overflow: "hidden",
        }}
      >
        <div className="testington">
          <Name />
        </div>
        <Menu />
      </div>
      <div className="dialogue-container blink">
        FULL-STACK REACT <br />& REACT-NATIVE DEVELOPER
      </div>

      <div className="background-pic slidedown">
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/newportfolio%2Fbg.png?alt=media&token=d397a40c-9b4e-4597-9d90-dd7d70ff9975"
          }
          alt="am full stack developer, plz hire mez"
        />
      </div>
      <div className="background-flowers slideup">
        <img
          src={
            "https://firebasestorage.googleapis.com/v0/b/joshgodinezdev.appspot.com/o/robotic_flowers.jpg?alt=media&token=aef76716-6b83-4c2a-9544-0c7a6b15ee87"
          }
          alt="am full stack developer, plz hire mez"
        />
      </div>
    </div>
  );
};

export default Home;
