// dependencies
import React from "react";
import { Link } from "react-router-dom";
// assetts
import { AiOutlineMenu } from "react-icons/ai";
import "./index.css";

const Menu = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 2,
        justifyContent: "flex-end",
        padding: "1.5em",
      }}
    >
      <div className="container-blob">
        <div className="blob"></div>
      </div>
      <Link
        to={"/nav"}
        style={{ color: "white" }}
        className="icon grow slideup"
      >
        <AiOutlineMenu style={{ zIndex: 200 }} />
      </Link>
    </div>
  );
};

export default Menu;
