// dependencies
import React from "react";

const Name = () => {
  return (
    <div style={{ display: "flex", cursor: "pointer", width: "100%" }}>
      <div
        style={{
          boxSizing: "border-box",
          color: "white",
          fontWeight: 500,
          fontSize: "1.5rem",
          fontSpacing: "1rem",
          lineHeight: "1.6rem",
          padding: "1rem 0rem",
          fontWrap: "nowrap",
          // fontFamily: "Poppins",
        }}
        className="slideup"
      >
        Josh Godinez
      </div>
    </div>
  );
};

export default Name;
