import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import "./example.css";

const Example = ({ title, bg, headline }) => {
  let { url } = useRouteMatch();

  const escapedString = title.toLowerCase().replaceAll(" ", "_");
  return (
    <Link to={`${url}/${escapedString}`}>
      <div className="example">
        <div className="grayfilter">
          <img src={bg} alt=" " />
        </div>
        <div className="gallery-content">
          <div className="headline">{headline}</div>
          <div className="title">{title}</div>
        </div>
      </div>
    </Link>
  );
};

export default Example;
