import React from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import { CgCloseR } from "react-icons/cg";
import { useHistory } from "react-router-dom";

const Nav = () => {
  let history = useHistory();

  return (
    <div className="Nav">
      <div
        style={{
          display: "flex",
          alignItems: "space-between",
          width: "100%",
          position: "absolute",
          top: 0,
          zIndex: 100,
          backgroundColor: "transparent",
        }}
      >
        <div
          className=""
          style={{
            display: "flex",
            flex: 2,
            justifyContent: "flex-end",
            padding: "1.5em",
            textTransform: "capitalize",
          }}
        >
          <Link onClick={() => history.goBack()} to={"/"}>
            <div className="element icon grow" style={{ color: "white" }}>
              <CgCloseR />
            </div>
          </Link>
        </div>
      </div>
      <div className="navpage-container">
        <Link to={"/"} className="navpage-link">
          <div>home</div>
        </Link>
        <Link to={"/work"} className="navpage-link">
          <div>select work</div>
        </Link>
        <Link to={"/skills"} className="navpage-link">
          <div>skills</div>
        </Link>
        <a href="https://www.github.com/joshjsx" className="navpage-link">
          <div>github</div>
        </a>
      </div>
      <div className="background-image">
        <img
          src="https://media1.giphy.com/media/bLdgTj2jCKe9Wf94Km/giphy.gif?cid=ecf05e47kgx8t19w1t1qcax1o74gwsrz2lz7rmdvnrml1p95&rid=giphy.gif&ct=g"
          alt=" "
        />
      </div>
      <div
        className="spacecat"
        style={{
          zIndex: 200,
          position: "absolute",
          bottom: "1em",
          right: "1em",
        }}
      >
        <img
          src="https://docs.rundeck.com/docs/assets/img/saucer-cat.30aa897b.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default Nav;
